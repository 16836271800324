/**
 * Helpers
 */

window.toggleClass = function (selector, className) {
  let element = document.querySelector(selector);
  if (typeof (element) != 'undefined' && element != null) {
    element.classList.toggle(className);
  }

}