
// https://pristine.js.org/
import Pristine from 'pristinejs';

let defaultConfig = {
  // class of the error text element
  errorTextClass: 'validation-error'
};

window.onload = function () {
  let contactForm = document.getElementById("contactForm");
  if (contactForm !== null) {
    validateContactForm(contactForm);
  }

};
/**
 * Validateinput from contact form
 * @param {*} form 
 */
function validateContactForm(form) {

  let pristine = new Pristine(form, defaultConfig);

  form.addEventListener('submit', function (e) {
    e.preventDefault();

    // check if the form is valid
    let isValid = pristine.validate(); // returns true or false
    if (true === isValid) {
      submitContactForm(form);
    }
    return;

  });
}
/**
 * Submit contact form
 * @param {*} form 
 */
function submitContactForm(form) {
  const url = 'https://restapi.vades.net/v1/common/contact';
  const formData = new FormData(form);
  const fetchParams = {
    method: 'POST', // or 'PUT'
    body: formData  // a FormData will automatically set the 'Content-Type'
  };

  return fetch(url, fetchParams)
    .then(checkError)
    .then((jsonResponse) => {
      console.log(jsonResponse);
      renderContactFormResponse(form, 'Thank you for contacting us. You are very important to us, all information received will always remain confidential.', 'is-success');
      form.reset();
    }).catch((error) => {
      console.log(error);
      renderContactFormResponse(form, 'Unable to send your message. Please try it later.', 'is-danger');
    });

}
/**
 * Check contact form api response
 * @param {*} response 
 */
function checkError(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  } else {
    throw Error(response.statusText);
  }
}
/**
 * Render contact form response
 * @param {*} form 
 * @param {*} message 
 * @param {*} type 
 */
function renderContactFormResponse(form, message, type) {
  // Removing old messages
  const rem = document.getElementsByClassName('notify');
  while (rem.length > 0) rem[0].remove();

  // Adding new message
  const elem = document.createElement('div');
  elem.setAttribute("class", "notify form-notify " + type);

  // add text
  elem.innerText = message;
  form.before(elem);
}




