/**
 * LightBox script
 * Source: https://www.w3schools.com/howto/howto_js_lightbox.asp
 * 
 */
var slideIndex = 1;
  //window.showSlides(slideIndex);

  // Open the Modal
  window.openModal = function() {
    document.getElementsByTagName("body")[0].classList.toggle('modal-open');
    //alert('This is LightBox Modal');
    document.getElementById("lightboxModal").style.display = "block";
  }
  
  // Close the Modal
  window.closeModal = function() {
    document.getElementsByTagName("body")[0].classList.toggle('modal-open');
    document.getElementById("lightboxModal").style.display = "none";
  }
  
 
  
  // Next/previous controls
  window.plusSlides = function(n) {
    showSlides(slideIndex += n);
  }
  
  // Thumbnail image controls
  window.currentSlide = function(n) {
    showSlides(slideIndex = n);
  }
  
  window.showSlides = function(n) {
    var i;
    var slides = document.getElementsByClassName("lightbox-slides");
    var lightboxImgCounter = document.getElementById("lightboxImgCounter");
  
    if (n > slides.length) {
        slideIndex = 1
    }
    if (n < 1) {
        slideIndex = slides.length
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[slideIndex-1].style.display = "block";
    lightboxImgCounter.innerHTML = slideIndex;
  }